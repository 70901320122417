import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import EmailInput from "src/components/marketing/email-input"
import GetStartedSection from "src/components/marketing/get-started-section"
import MindanceAppSection from "src/components/marketing/mindance-app-section"
import SignatureSection from "src/components/marketing/signature-section"
import TitleStrip from "src/components/marketing/title-strip"
import SEO from "src/components/seo"
import "src/styles/pages/landing-page.scss"

export default function HugoBossPage() {
  const REGISTRATION_CODE = "w5_DocK3Z8Ohw43Cv8Kx"

  return (
    <Layout className="marketing-page-root">
      <TitleStrip logos={["/assets/img/marketing/mindance_anmeldung.png"]} />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title: "Deine Reise zu mehr Ruhe und Entspannung mit Mindance",
          description:
            "Mitarbeitende erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordere dazu Deinen Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen.",
        }}
      >
        <EmailInput
          short
          placeholder="E-Mail geschäftlich"
          registrationCode={REGISTRATION_CODE}
        />
      </Jumbotron>
      <SEO
        title="Landing Page"
        description="Mitarbeitende erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen."
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
      />
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h1>Stress reduzieren und Wohlbefinden steigern</h1>
          <p>
            Der Arbeitsalltag – egal, ob im Büro oder in der Filiale – kann 
            einen schnell an die eigenen Grenzen bringen. Stress, Anspannung oder 
            Frustration sind nicht selten die Folge. Mit Mindance hast Du ab sofort 
            die Möglichkeit, Entspannungsübungen und Meditationen in Deinen Alltag 
            einzubauen, um Stress zu reduzieren, Schlafprobleme zu überwinden und Dein 
            Wohlbefinden zu verbessern.
          </p>
          <br />
          <p>
            <b>Mindance steht allen Mitarbeitenden kostenfrei zur Verfügung.</b>
          </p>
          <br />
          <p>
            Mindance ist ein Berliner Unternehmen, das sich der Mission 
            verschrieben hat, zu einer Welt beizutragen, in der mentale 
            Gesundheit denselben Stellenwert hat wie körperliche Gesundheit und in der 
            arbeitsbedingte psychische Erkrankungen der Vergangenheit 
            angehören. Alle Mitarbeiter*innen erhalten kostenlosen 
            Zugriff auf das Angebot von Mindance.
            <br />
            <br />
            <b className="green-text text-l">
              Das ist die Chance für Dich, Deine psychische Gesundheit nachhaltig zu fördern!
            </b>
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/marketing/mindfrinds_achtsamkeit_reflection.jpg"
          />
        </Grid>
      </Grid>
      <MindanceAppSection
        titles={[
          "Checke Deine mentale Gesundheit und erhalte Deinen persönlichen Übungsplan",
          "Entdecke die große Auswahl an Übungen und Kursen",
          "Verfolge Fortschritte auf einen Blick",
        ]}
        descriptions={[
          "Starte direkt mit unserem Fragebogen, der Deine psychische Gesundheit detailliert erfasst. Die Ergebnisse zeigen auf, in welchen Bereichen Ihre mentale Gesundheit einer Förderung bedarf. Auf Deinen persönlichen Ergebnis basierend erhältst Du einen Plan mit verschiedenen Übungen. Zum Schluss bestimmst Du selbst, wie viel Zeit Du pro Woche investieren möchtest. Ob einmal wöchentlich 5 Minuten oder täglich 20 Minuten - Du hast die Wahl",
          "In der App findst Du ein 8-wöchiges Grundlagentraining, das Dich dabei unterstützen kann, Deine eigenen Gedanken und Gefühle besser zu verstehen. Außerdem kannst Du nach Belieben aus Übungen zu verschiedenen Themenbereichen, wie “Stress reduzieren”, “Beziehungen stärken”, “Besser schlafen” oder “Achtsamkeit kultivieren” wählen und zwischen 5 und 15 Minuten die Übungslänge einstellen, die für Dich in dem Moment ideal ist.",
          "In Deinem Profil findest Du die Timeline Deiner absolvierten Übungen, Statistiken zu Deinem Training mit Mindance und die Ergebnisse des Fragebogens. Unser Tipp: Regelmäßiges Üben macht den/die Meister*in, damit ist Regelmäßigkeit erst einmal wichtiger als langes Üben.",
        ]}
      />
      <GetStartedSection
        title="Lege direkt los"
        subtitle="Um die App zu installieren, folge einfach diesen drei Schritten"
        steps={[
          "Lade Dir die Mindance App im AppStore/Google PlayStore und dem Stichwort “Mindance” herunter.",
          "Gebe hier Deine E-Mail Adresse ein, um einen Aktivierungscode zu erhalten.",
          "Erstelle Dir unter Verwendung Deines Aktivierungscodes Dein persönliches, anonymes Profil.",
        ]}
        emailComponent={
          <EmailInput
            placeholder="E-Mail Adresse"
            registrationCode={REGISTRATION_CODE}
          />
        }
      />
      <SignatureSection />
    </Layout>
  )
}
